import React, { Component } from 'react'
import Footer from './Footer'

export default class About extends Component {
  render() {
    return (
      <div class="main">
        <div class="about1">

        </div>
        <div class="text-1">
        <h1>About</h1>
        <ul>
        <li>Be more connected with your food and know exactly where it's coming from</li>
        <li>Eating vegetables as FRESH as possible meaning higher nutrient in takes</li>
        <li>Reduce transportation of vegetables that loose a majority of its nutrients over the distance and avoid the chemicals used to keep these vegetable fresh</li>
        <li>As a result there is greater energy conservation and the use of these clean energy greatly reduces the instances of air and water pollution.</li>
          </ul>
        </div>
        <div class="about2">
          
        </div>
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
