import React, { Component } from 'react'
import Footer from './Footer'

export default class Items extends Component {
  render() {
    return (
      <div class="main">
        <div class="greenhouse-container">
          <div class="screen1">
            
            
          </div>

          <div class="text-1">
          <h1>Options</h1>
          </div>

          <div class="screen2">
            <h1>Solutions</h1>
          </div>
          <div class="text-1">
          <h1>Greenhouse</h1>
        </div>
        </div>
       
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
