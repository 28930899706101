import React, { Component } from 'react'
import Footer from './Footer'


export default class Home extends Component {
  render() {
    return (
      <div class="main">
        <div class="home-container">
          <div class="conatiner-1">
            {/* <img class="greenhouse-img" src={GreenHouse} alt='' /> */}
            <div class="text">
              <h1 class="slogan">
                Growing your own food has never been easier
                
                </h1>
                <a href="/shop" ><button class="greenhouse-button">Get The Greenhouse</button></a>
       
        
            </div>
            </div>
        <div class="home1-container">
          <div class="home1">
          <h1>Mission</h1>
    <p class="griditem">To give people the ability to grow nutritious food with miniaml time and effort.
            This helps people:
      </p>
      
            </div>
            <img class="shop-image" src="https://i.imgur.com/mdNYsJA.jpg"/>
            </div>
          <div class="pic-2">
          <div class="text-15">
              <h1 class="slogan-2">
                Growing your own food has never been easier
                
                </h1>
                <a href="/items" ><button class="greenhouse-button">Get The Greenhouse</button></a>
       
        
            </div> 
          </div>
          <div class="home2-container">
            <h7>The Benefits</h7>
            <div class="home2">
            <p>Grow your own food and share that connection with where what you put in your body comes from. </p>
            <p>Save money shopping, fresher foods consumed, </p>
            </div>
            </div>
        </div>
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
