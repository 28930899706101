const axios = require('axios')

const baseUrl = process.env.NODE_ENV ===
  'production' ? 'https://ivka.herokuapp.com/' :
  'http://localhost:3000'

const api = axios.create({
  baseURL: baseUrl
});

export const postCart = async (id, postData) => {
  const resp = await api.post(`/cart`)
  return resp.data
}