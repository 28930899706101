import React, { Component } from "react";
import Footer from "./Footer";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm'
import StripeCheckout from 'react-stripe-checkout';

const stripePromise = loadStripe("pk_test_WbtSrcyvMS41Sf3DbL92yb49006xN53OKQ");

export default class Shop extends Component {
  render() {
    return (
      <div class="main">
        <div class="shop-container">
          <img class="shop-image" src="https://i.imgur.com/mdNYsJA.jpg"/>
          <div class="shop1">
            <h1>Greenhouse 8x10</h1>
            <p>Add the gift of growing your own food</p>
            <ul>
              <li>Hydroponic system</li>
              <li>No watering required</li>
              <li>Just plant pick and eat</li>
              <p>$15,999</p>
            </ul>
            
            <a href="/checkout"><button class="shop-add-cart">Add to cart</button></a>
            {/* <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements> */}
          </div>
          {/* <div class="text-1">
            <h1>Shop</h1>
          </div> */}
        </div>
        <div class="footer">
          <Footer class="footer" />
        </div>
      </div>
    );
  }
}
