import React, { Component } from "react";
import Profile from "../images/profile.svg";
import Shopping from "../images/shopping.svg";
import Arrow from "../images/arrow.svg";

export default class Nav extends Component {
  render() {
    return (
      <div class="nav-container">
        <div class="nav">
          <nav>
            <a class="nav-link" href="/">
              <p class="ivka">IVKA</p>
            </a>

            <div class="nav-links">
              <a class="nav-link" href="/about">
                <p>Our Mission</p>
              </a>
              <a class="nav-link" href="/items">
                <p>How It Works</p>
              </a>
              <a class="nav-link" href="/resources">
                <p>Resources</p>
              </a>
              <a class="nav-link-shop" href="/shop">
                Shop
              </a>
              <div class="nav-call-container">
              <a class="nav-link-call" href='/'>Or call (347) 400-6623</a>
            </div>
            </div>

            <div class="nav-functions">
              {/* <a href='profile' class="nav-profile" ><img src={Profile} alt='/' class="nav-profile" /></a>
              <img src={Arrow} alt='' /> */}
              <a href="/cart"><img src={Shopping} alt='' class="nav-cart" /></a>
            </div>
          </nav>
        </div>

        <div class="announcements">
          <p>Projects</p>
        </div>
      </div>
    );
  }
}
