import React, { Component } from 'react'
import Footer from './Footer'

export default class Resources extends Component {
  render() {
    return (
      <div class="main">
        <div class="resources1">
          <h1>Resources</h1>
          <a target="_blank" rel="noopener noreferrer" class="resource-link"
            href="https://zachbushmd.com/blog/farming/">
            Zach Bush MD
             </a>
          </div> 
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
