import React, {Component} from 'react';
import { Route, Switch } from 'react-router-dom'
import {withRouter} from 'react-router'
import Nav from './components/Nav'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Items from './components/Items'
import Shop from './components/Shop'
import Resources from './components/Resources'
import Profile from './components/Profile'
import Checkout from './components/Checkout'
// import Footer from './components/Footer'
import './App.css';
import './styles/Nav.css'
import './styles/Footer.css'
import './styles/Home.css'
import './styles/Items.css'
import './styles/Shop.css'
import './styles/About.css'
import './styles/Contact.css'
import './styles/Resources.css'

import {
  postCart
} from './services/api-helper'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm"
const stripePromise = loadStripe("pk_test_WbtSrcyvMS41Sf3DbL92yb49006xN53OKQ");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  } 

  addCart = async () => {
    const newCart = await postCart()
  }
  
  render() {
    return (
      <div className="App">
        <Nav />
        {/* <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements> */}
        <div class="route">
        <Switch>
          <Route exact path="/" render={(props) => (
            <Home />
          )}
          />
          <Route exact path="/about" render={(props) => (
            <About
            />
            )} />
           <Route exact path="/items" render={(props) => (
            <Items
            />
            )} /> 
          <Route exact path="/contact" render={(props) => (
            <Contact
            />
            )} />
            <Route exact path="/shop" render={(props) => (
            <Shop
            />
            )} />
            <Route exact path="/resources" render={(props) => (
            <Resources
            />
            )} />
            <Route exact path="/profile" render={(props) => (
            <Profile
            />
            )} />
            <Route exact path="/cart" render={(props) => (
            <Checkout
            />
            )} />
            
          </Switch>
        </div>
        {/* <div class="footer">
          <Footer class="footer" />
          </div> */}
      </div>
    );
  }
}

export default withRouter(App);
