import React, { Component } from 'react'
import Footer from './Footer'

export default class Contact extends Component {
  render() {
    return (
      <div class="main">
        <div class="contact1">
        <h1>Contact</h1>
        <p>Phone:347-400-6622</p>
          <p>Email: Ivkafarms@gmail.com</p>
          </div>
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
