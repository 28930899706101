import React from 'react'
// import Twitter from '../images/twitter1.svg'
import Linkedin from '../images/linkedin.svg'
import Insta from '../images/instagram.svg'
import Youtube from '../images/youtube.svg'


const Footer = () => {
  return (
    <div class="footer-container">
      <div class="footer">
        <h1 class="footer-header">Ivka </h1>
        <div class="service">
          
        </div>
        <div class="learn">

        </div>
        
        <div class="social">
          <a
            target="_blank" rel="noopener noreferrer" class="css-13nadwe"
            href="https://www.instagram.com/ivkafarms/">
            <img class="social-s" src={Insta} alt=''/>
          </a>
          {/* <a
            target="_blank" rel="noopener noreferrer" class="css-13nadwe"
            href="https://www.youtube.com/channel/UCoZ6cSEyy2oFSMcKyjSOBzw/featured?view_as=public">
              <img class="social-s" src={Youtube} alt=''/>
          </a> */}
         
          {/* <Link to="https://twitter.com/">
          </Link> */}
          {/* <a
            target="_blank" rel="noopener noreferrer" class="css-13nadwe"
            href="https://www.linkedin.com/">
            <img class="social-s" src={Linkedin} alt='' />
          </a>
          </div>
          <div class="mailing-list">
          */}
        </div>
        <div class="connect">
          <a class="contact" href="/contact">
            Contact
          </a>
          <a class="contact" href="/careers">
            Careers
          </a>
          </div>
      </div>
      <div class="co-care">
            Copyright © {new Date().getFullYear()}, IVKA®
      </div>
      </div>
  )
}

export default Footer;