import React, { Component } from 'react'
import Footer from './Footer'

export default class Profile extends Component {
  render() {
    return (
      <div>
        <h1>Yo Yo</h1>
        <div class="footer">
          <Footer class="footer"/>
          </div>
      </div>
    )
  }
}
