import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";

class Checkout extends Component {
  onToken = (token, addresses) => {
    // TODO: Send the token information and any other
    // relevant information to your payment process
    // server, wait for the response, and update the UI
    // accordingly. How this is done is up to you. Using
    // XHR, fetch, or a GraphQL mutation is typical.
  };
  render() {
    return (
      <div>
        <StripeCheckout
          stripeKey="your_PUBLISHABLE_stripe_key"
          token={this.onToken}
          stripeKey="pk_test_WbtSrcyvMS41Sf3DbL92yb49006xN53OKQ"
          amount="500"
          billingAddress=""
          description=""
          locale="auto"
          name="www.ivka.com"
          zipCode="11111"
        />
      </div>
    );
  }
}

export default Checkout;
